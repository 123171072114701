import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <section className='honbun'>
      <p>{`【補足】`}</p>
      <p>{`これ以降、マスターコンプで音圧の作業をするときは、動画にあるように「リファレンスのボリューム」を0dBに戻してから作業します。`}</p>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      